// Global vendors
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "./assets/fontawesome-pro/scss/fontawesome.scss";
@import "./assets/fontawesome-pro/scss/thin.scss";


// Globas CSS
@import "./assets/css/dinego.scss";

html {
  scroll-behavior: smooth;
  height: 100%;
}

.fv-help-block {
  color: #f32129;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
