/************************
         FONTS
*************************/
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-latin-300.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v30-latin-regular.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/roboto-v30-latin-italic.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v30-latin-500.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v30-latin-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto-v30-latin-900.woff2") format("woff2");
}

.fs-10 {
  font-size: 10px !important;
}

/************************
      ROOT
*************************/
:root {
  --font-primary: "Roboto";
  --color-primary: #152244;
  --color-secondary: #ebf0f4;
  --color-primary-complementary: #ffffff;
  --color-secondary-complementary: #152244;
  --color-background: #ffffff;
  --color-danger: #ea4159;
  --color-success: #3ecf8e;
}

/************************
      PREDEFINED
*************************/
body {
  font-size: 14px;
  font-family: var(--font-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  padding: 0;
  margin: 0;
  position: relative;
}

.w-60 {
  width: 60px;
}

.px-2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.text-16 {
  font-size: 16px !important;
}

.landing-body {
  height: 100dvh;
}

.landing-mid-block {
  padding-top: 150px;
}

.restaurant-body {
  padding-top: 80px;
}

.bg-color {
  background-color: var(--color-background);
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.text-white {
  color: #ffffff;
}

.shadow-sm {
  box-shadow: 0px 3px 6px 0px #0000001a;
}

.shadow-lg {
  box-shadow: 0px 3px 6px 0px #00000029;
}

.shadow-header {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.fs-16 {
  font-size: 16px;
}

/**********BUTTONS***********/
.btn {
  font-size: 14px;
  padding: 10px 20px;
  line-height: 1.12;
  border-radius: 22px;
}

.btn:active {
  border-color: var(--color-primary);
}

.btn-primary {
  color: var(--color-primary-complementary);
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary);
}

.btn-primary:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-primary:active {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.btn-primary:disabled {
  background-color: var(--color-primary);
  color: var(--color-primary-complementary);
  border-color: var(--color-primary);
  opacity: 1;
}

.btn-secondary {
  color: var(--color-primary) !important;
  background-color: var(--color-primary-complementary);
  border-color: var(--color-primary);
}

.btn-secondary:disabled {
  background-color: var(--color-primary-complementary);
  color: var(--color-primary);
  border-color: var(--color-primary);
  opacity: 1;
}

.btn-secondary:active {
  background-color: var(--color-primary-complementary) !important;
  border-color: var(--color-primary) !important;
}

.btn-secondary:hover {
  background-color: var(--color-primary-complementary) !important;
  border-color: var(--color-primary);
}

.btn:focus-visible {
  box-shadow: none !important;
}

.btn-primary-outline {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
}

.btn-primary-outline:disabled {
  opacity: 1;
}

.btn-primary-outline:hover {
  border: 1px solid var(--color-primary);
  color: var(--color-primary-complementary);
  background-color: var(--color-primary);
}

.btn-link {
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  padding: 0 5px;
  text-decoration: none;
  color: var(--color-primary);
  background-color: transparent;
}

.btn-xs {
  font-size: 8px;
  padding: 3px 8px;
}

.btn-sm {
  font-size: 12px;
  padding: 8px 15px;
}

.btn-md {
  font-size: 12px;
  padding: 3px 8px 3px 8px;
  white-space: nowrap;
}

.btn-text {
  font-size: 14px;
}

.btn-lg {
  font-size: 20px;
  padding: 2px 10px;
}

.btn-xl {
  font-size: 16px;
  padding: 12px 24px;
}

.btn-link:hover {
  color: var(--color-primary);
  background-color: transparent;
}

.btn-link:active {
  background-color: transparent !important;
  color: var(--color-primary) !important;
}

.btn-img {
  width: 20px;
  height: 20px;
}

.btn-img-lg {
  height: 25px;
  width: auto;
  filter: brightness(0) saturate(100%) invert(9%) sepia(27%) saturate(3107%) hue-rotate(200deg) brightness(104%) contrast(93%);
}

.btn-img-xl {
  height: 28px;
  width: 28px;
}

.img-border {
  border: 1px solid var(--color-primary);
  border-radius: 50%;
}

:focus-visible {
  outline: none;
}

.btn-fixed {
  min-width: 265px;
}

.badge {
  font-size: 10px;
  line-height: 1.25;
  padding: 4px 8px 3px 8px;
  border-radius: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-primary {
  color: var(--color-primary-complementary);
  background-color: var(--color-primary);
}

.badge-primary-outline {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.badge-lg {
  padding: 5px 12px;
  font-size: 12px;
}

.badge-primary i,
.badge-primary-outline i {
  margin-right: 4px;
}

.fa-chevron-left::before {
  content: fa-content($fa-var-chevron-left);
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--color-primary);
  font-size: 20px;
  font-weight: 900;
  padding-right: 5px;
}

.badge-primary .fa-unknown,
.badge-primary-outline .fa-unknown {
  margin-right: 0;
}

/**********SPLASH************/
#splash-screen.initial {
  display: none; // hide initial splash, once app is loaded
}

#splash-screen {
  position: fixed; // override since body is position:relative now
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.modal-header {
  border-bottom: none;
}

/***********LOADER***********/
#loader-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-background);
}

#loader-screen img {
  margin-bottom: 30px;
}

#loader-screen.hidden {
  opacity: 0;
  visibility: hidden;
}

.loader-spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

.loader-spinner .path {
  stroke: var(--color-primary);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.loader-container {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  border-radius: 5px;
}

.loader-style {
  font-size: 60px;
  color: var(--color-primary);
  font-weight: bold;
}

.button-loader-primary {
  font-size: 16px;
  color: var(--color-secondary);
  font-weight: bold;
}

.button-loader-secondary {
  font-size: 16px;
  color: var(--color-primary);
  font-weight: bold;
}

.button-loader-secondary {
  font-size: 16px;
  color: var(--color-danger);
  font-weight: bold;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/**********OVERLAY***********/
.landing-overlay::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;

  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(var(--color-background), 1) 50%, rgba(var(--color-background), 1) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%);
}

.menu-item-overlay::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #00000026, #000000c9);
}

/*********TEXT***********/
a {
  color: var(--color-primary);
  text-decoration: none;
}

.two-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.one-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1 {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 5px;
}

h2 {
  font-size: 25px;
  line-height: 1.2;
  margin-bottom: 5px;
}

h3 {
  font-size: 20px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 5px;
}

h4 {
  font-size: 18px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 5px;
}

h5 {
  font-size: 14px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 5px;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.fw-black {
  font-weight: 900;
}

.fw-normal {
  font-weight: 400;
}

.border,
.border-bottom,
.border-top,
.border-left,
.border-right {
  border-color: var(--color-primary);
}

.form-control {
  background-color: var(--color-background);
}

.form-check-input {
  border: 1px solid var(--color-primary);
  background-color: var(--color-background);
  height: 18px;
  width: 18px;
  opacity: 0.5;
  transition: all 0.2s;
  margin-right: 5px;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.form-check-input:checked {
  background-color: transparent;
  border-color: var(--color-primary);
}

.form-check-input:checked::after {
  content: fa-content($fa-var-check);
  font-family: $fa-style-family;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--color-primary);
  font-size: 12px;
  font-weight: 700;
}

.form-check-label {
  color: var(--color-primary);
  font-weight: 300;
  opacity: 0.75;
  transition: all 0.2s;
  line-height: 1.8;
}

.form-check-input:checked+.form-check-label {
  opacity: 1;
}

.form-check-input:checked {
  opacity: 1;
}

.form-label {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
  text-align: start;
}

.form-control {
  font-size: 14px;
  line-height: 1.4;
  padding: 12px 20px;
  border-radius: 22px;
  border: 1px solid var(--color-primary);
}

.form-select {
  font-size: 14px;
  line-height: 1.4;
  padding: 12px 20px;
  border-radius: 22px;
  border: 1px solid var(--color-primary);
}

.form-control::placeholder {
  opacity: 0.5;
}

.form-control:focus {
  border-color: var(--color-primary);
  outline: none;
  box-shadow: none;
  background-color: var(--color-background);
}

header.fixed-top {
  height: 120px;
}

.fixed-width {
  max-width: 265px;
}

/**********************
  LANDING PAGE
**********************/
.landing-page-section {
  padding: 40px 0;
  height: 100dvh;
}

.landing-page-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
}

.landing-page-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.landing-page-block {
  position: relative;
  z-index: 3;
}

.landing-logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.landing-logo-box img {
  height: auto;
  width: 72px;
}

.landing-adress {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: var(--color-primary);
  opacity: 0.75;
  margin-bottom: 35px;
}

.order-select-title,
.date-select-title {
  line-height: 2.14;
  opacity: 0.75;
  text-align: center;
}

.asap-radio-button {
  padding-top: 6px;
  padding-bottom: 20px;
}

.date-time-wrapper {
  padding-bottom: 20px;
}

.landing-buttons-box {
  border-radius: 25px;
  transition: all 0.3s;
  background-color: var(--color-secondary);
  padding: 3px;
  margin-bottom: 25px;
  margin-top: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
}

.landing-toggle {
  opacity: 0.75;
  background-color: transparent !important;
  border-radius: 25px !important;
  border: none !important;
  position: relative;
  z-index: 20;
  flex-grow: 1;
  justify-content: center;
  padding: 13px 17px !important;
}

.landing-toggle:hover {
  color: var(--color-primary);
}

.landing-toggle.active {
  opacity: 1;
  color: var(--color-primary-complementary) !important;
  position: relative;
  z-index: 20;
}

.landing-buttons-box.active-landing-buttons-box {
  transform: all 0.3s;
}

.landing-toggle-left::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  visibility: hidden;
  bottom: 0;
  border-radius: 25px;
  background-color: var(--color-primary);
  z-index: -1;
  transition: all 0.2s;
}

.landing-toggle-left.active::after {
  left: 0;
  visibility: visible;
}

.landing-toggle-right::after {
  position: absolute;
  content: "";
  width: 90%;
  height: 100%;
  top: 0;
  right: 95%;
  visibility: hidden;
  bottom: 0;
  border-radius: 25px;
  background-color: var(--color-primary);
  z-index: -1;
  transition: all 0.2s;
}

.landing-toggle-right.active::after {
  right: 0;
  width: 100%;
  visibility: visible;
}

.time-from-select,
.date-form-select {
  padding: 0;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-primary);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  padding: 5px;
  text-align: right;
}

.language-picker.welcome {
  position: relative;
  top: -15px;
}

.language-picker .dropdown-menu {
  padding: 0;
  border: 1px solid #e3e3e3;
  margin-top: 10px !important;
  background-color: var(--color-background);
  border-radius: 5px;
  overflow: hidden;
  right: 0;
  left: auto;
}

.language-picker .dropdown-item img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.language-picker .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.12;
  padding: 14px 15px;
  border-bottom: 1px solid #15224421;
  position: relative;
  color: var(--color-primary);
}

.language-picker li:last-child .dropdown-item {
  border-bottom: none;
}

.language-picker .dropdown-item.active,
.language-picker .dropdown-item:hover {
  background-color: #ecf0f1;
}

.language-picker .dropdown-item.active::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url("../img/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  filter: brightness(0) saturate(100%) invert(9%) sepia(74%) saturate(1227%) hue-rotate(202deg) brightness(95%) contrast(95%);
}

/************************
    HEADER
*************************/
.navbar {
  padding: 25px 3px;
}

.navbar-second {
  padding: 25px 3px 21px 3px;
}

.navbar-toggler {
  padding: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  flex-shrink: 0;
}

.navbar-toggler img {
  width: 100%;
  height: 100%;
}

.navbar-brand {
  height: 30px;
  margin: 0;
  width: auto;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.25;
  color: var(--color-primary);
  white-space: normal;
}

.navbar-brand img {
  height: 100%;
  width: auto;
  margin-right: 10px;
}

.filter-toggler {
  border: none;
  background-color: transparent;
  margin-left: 19px;
  margin-right: 19px;
  padding: 0;
}

.filter-toggler img {
  height: 20px;
  width: auto;
}

.menu-time-box {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas {
  background-color: var(--color-background);
  width: auto !important;
  min-width: 60%;
  border-left: none !important;
}

.offcanvas-header {
  padding: 31px 21px 5px 21px;
}

.offcanvas-profile {
  margin-bottom: 5px;
  padding: 15px;
  width: 100%;
  text-decoration: none;
  color: var(--color-primary);
}

.offcanvas-profile:hover {
  background-color: #ecf0f1;
}

.offcanvas-profile-text-box {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  margin-left: 5px;
  color: var(--color-primary);
}

.offcanvas-profile-img-box {
  height: 30px;
  width: 30px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  overflow: hidden;
}

.offcanvas-profile-img-box img {
  width: 100%;
  display: block;
  height: 100%;
}

.offcanvas-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px;
}

.navbar-nav {
  padding: 0;
  width: 100%;
}

.nav-link {
  font-size: 16px;
  line-height: 1.12;
  color: var(--color-primary);
  padding: 15px 17px;
}

.nav-link i {
  width: 15px;
}

.nav-link:hover {
  color: var(--color-primary);
  background-color: #ecf0f1;
}

.navbar-nav .dropdown-toggle {
  position: relative;
  padding-right: 10px;
  color: var(--color-primary);
}

.navbar-nav .dropdown-toggle::after {
  display: none;
}

.navbar-nav .dropdown-menu {
  border: none;
  width: 100%;
  padding-top: 0;
}

.lang {
  padding-left: 45px !important;
}

.lang-img-box {
  width: 15px;
}

.lang-img-box>img {
  width: 20px;
  border-radius: 50%;
}

button.dropdown-toggle::after {
  display: none;
}

.navbar-nav .dropdown-item {
  padding: 15px 25px;
  position: relative;
  color: var(--color-primary);
}

.navbar-nav .dropdown-item:hover {
  background-color: #ecf0f1;
}

.navbar-nav .nav-link.show {
  color: var(--color-primary);
}

.navbar-nav .dropdown-item img {
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.navbar-nav .dropdown-item.active::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url(../img/check.svg);
  background-size: contain;
  background-repeat: no-repeat;
  filter: brightness(0) saturate(100%) invert(9%) sepia(74%) saturate(1227%) hue-rotate(202deg) brightness(95%) contrast(95%);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ecf0f1;
  color: var(--color-primary);
}

.header-filter-wrapper {
  background-color: var(--color-background);
  z-index: 11;
  top: 0;
  left: 100%;
  padding-top: 25px;
  transition: all 0.4s;
  width: 100%;
  height: 120px;
}

.filter-show {
  left: 50%;
  transform: translateX(-50%);
}

.btn-search {
  position: absolute;
  left: 24px;
  top: 13px;
  font-size: 20px;
}

.menu-search-btn img {
  height: 15px;
  width: auto;
}

.menu-search-input:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.menu-search-input:focus-visible {
  outline: none;
}

.menu-search-input::placeholder {
  font-size: 16px;
  color: var(--color-primary);
  opacity: 0.5;
}

.filter-top {
  padding: 0 15px;
}

.filter-bottom {
  list-style-type: none;
  margin: 0;
  overflow-x: auto;
  padding-left: 15px;
  padding-top: 14px;
  padding-bottom: 15px;
}

.filter-bottom a img {
  height: 8px;
  position: relative;
  top: -2px;
  margin-right: 2px;
  transition: all 0.3s;
}

.filter-bottom a:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(12deg) brightness(107%) contrast(102%);
}

.date-time-modal .modal-header {
  padding: 0;
  padding-bottom: 10px;
  border-bottom: none;
}

.date-time-modal .modal-dialog {
  background-color: var(--color-background);
  padding: 25px 20px 35px 20px;
  border-radius: 10px;
  top: 120px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.date-time-modal .modal-content {
  background-color: var(--color-background);
  border: none;
}

/************************
   RESTAURANT MENU
*************************/
.restaurant-menu {
  z-index: 10;
  padding: 7px 0 12px 0;
  margin: 0;
  list-style-type: none;
  overflow-x: auto;
  background-color: var(--color-background);
}

.restaurant-menu.sticky-top {
  top: 80px;
}

.restaurant-menu li a {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 18px;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  padding: 0 3px;
}

.restaurant-menu li {
  padding: 0 5px;
}

.restaurant-menu li a::after {
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  position: absolute;
  content: "";
  background-color: #707070;
  transition: all 0.3s;
}

.restaurant-menu li a.active::after {
  width: 100%;
}

.active-category {
  border-bottom: 2px solid var(--color-primary) !important;
}

/***************************
  RESTAURANT MENU ITEMS
****************************/
.restaurant-menu-item-header {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 120px;
  padding: 15px;

  .category-title {
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  }
}

.restaurant-menu-item-header-img-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.restaurant-menu-item-header-img-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.restaurant-menu-item-header-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.restaurant-menu-article {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.menu-article-img-box {
  -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
  height: 65px;
  width: 65px;
  flex-shrink: 0;
  border-radius: 3px;
  overflow: hidden;
}

.menu-article-img-box img {
  width: 100%;
  object-fit: cover;
}

.menu-article-text-box {
  padding-left: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menu-article-text-child {
  flex: 1;
  width: 100%;
}

.menu-article-text-box h5 {
  line-height: 1.3;
}

.restaurant-menu-article a {
  text-decoration: none;
  color: var(--color-primary);
}

.menu-article-about {
  line-height: 1.4;
  color: var(--color-primary);
  opacity: 0.75;
}

.menu-article-categories img {
  width: 7px;
  height: 7px;
  filter: brightness(0) saturate(100%) invert(99%) sepia(81%) saturate(0%) hue-rotate(112deg) brightness(110%) contrast(101%);
}

.price-box {
  font-size: 12px;
  flex-shrink: 0;
  line-height: 1;
  margin-bottom: 0.5px;
}

.price-box span {
  font-size: 18px;
  margin-left: 4px;
}

.addons-box {
  .price-box {
    font-size: 10px;
  }
}

.restaurant-menu-article li {
  padding: 15px 0;
  display: block;
}

.input-btn {
  width: 25px;
  text-align: center;
  border: none;
  padding: 0;
}

.input-btn:focus {
  box-shadow: none;
}

.filter-idicator {
  position: absolute;
  top: -3px;
  right: -7px;
  width: 7px;
  height: 7px;
  background-color: var(--color-danger);
  border-radius: 50%;
}

/************************
    MINI CART
*************************/
.mini-cart {
  bottom: 20px;
  min-width: 100%;
}

.mini-cart-box {
  background-color: var(--color-background);
  border-radius: 30px;
  padding: 8px;
}

.mini-cart-icon-box {
  height: 44px;
  width: 44px;
  background-color: var(--color-primary);
  color: var(--color-primary-complementary);
  border-radius: 50%;
  font-size: 20px;
}

.mini-cart-quantity-box {
  background-color: var(--color-danger);
  border-radius: 50%;
  width: 13px;
  height: 13px;
  top: 0;
  right: 0;
  font-weight: 900;
  font-size: 8px;
}

.amount-left {
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
}

.amount-right span {
  font-size: 8px;
  font-weight: 700;
  opacity: 0.8;
  margin-bottom: 2px;
}

.mini-cart-price {
  font-size: 22px;
  font-weight: 900;
  line-height: 1;
  opacity: 1;
}

/************************
    PRODUCT MODAL
*************************/
.product-modal-img-box {
  width: 150px;
  height: 150px;
  border-radius: 6px;
  margin-bottom: -75px;
}

.product-modal-img-box img {
  border-radius: 6px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));
  position: relative;
  z-index: 22;
}

.menu-modal::before {
  content: "";
  height: 25px;
  width: 16px;
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 20px;
  background-image: url("../../assets/img/chevron-left.svg");
  display: block;
  text-align: center;
  font-size: 12px;
}

.menu-modal .modal-dialog {
  margin: 0;
  position: absolute;
  bottom: 0;
  height: auto;
  max-height: 100%;
  width: 100%;
}

.menu-modal .modal-content {
  margin-top: 60px;
  border-radius: 0;
  background-color: transparent;
  border: none;
}

.menu-modal .modal-body {
  background-color: var(--color-background);
  padding: 75px 15px 20px 15px;
  position: relative;
  z-index: 2;
}

.modal-exit {
  margin-top: 25px;
  margin-left: 15px;
}

.product-modal-top {
  padding-top: 20px;
}

.product-modal-price-box {
  font-size: 12px;
  margin-left: 15px;
}

.product-modal-price-box span {
  font-size: 18px;
  margin-left: 2px;
}

.product-modal-category span {
  line-height: 1.7;
  opacity: 0.75;
  margin-right: 7px;
}

.product-modal-description {
  padding: 15px 0;
}

.description-header {
  line-height: 2;
}

.description-content {
  line-height: 1.4;
  opacity: 0.75;
}

.form-check-price {
  font-size: 10px;
  line-height: 1.2;
}

.form-check-price span {
  font-size: 14px;
}

.addons-info {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
}

.chf-box {
  font-size: 14px;
}

.quantity-box {
  background-color: var(--color-background);
  border-radius: 26px;
  font-size: 15px;
  padding: 3.5px 0;
  position: relative;
}

.cart-minus {
  opacity: 0.5;
}

.quantity-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 26px;
  border: 1px solid var(--color-primary);
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0.5;
}

.quantity-box span,
.quantity-box input {
  font-size: 18px;
}

.additional-box {
  line-height: 1.4;
}

.additional-req {
  border-radius: 32px;
  font-size: 12px;
  padding: 6px 12px;
  resize: none;
}

.additional-req:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.additional-note {
  font-size: 12px;
  line-height: 1.45;
  font-weight: 700;
}

.additional-opened,
.additional-note {
  display: none;
}

/***************************
      ACCOUNT
****************************/
.account-navbar {
  font-size: 16px;
  line-height: 1.1;
}

.account-text {
  margin-bottom: 30px;
  line-height: 1.5;
}

.account-line {
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  margin: 50px 0;
}

.account-line::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 45%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--color-primary);
  opacity: 0.1;
}

.account-line::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 45%;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--color-primary);
  opacity: 0.1;
}

.login-button-wrapper {
  width: fit-content;
}

.btn-password {
  min-width: unset !important;
}

.account-map-wrapper {
  height: 250px;
  width: 100%;
}

.account-map-wrapper iframe {
  width: 100%;
  height: 100%;
}

.accoont-profile-image-box {
  position: relative;
  margin-top: 10px;
  margin-bottom: 40px;
  height: 112px;
  width: 112px;
  border-radius: 50%;
  box-shadow: 0 0 0 1.7px var(--color-primary);
}

.accoont-profile-image-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-button-wrapper {
  bottom: -20px;
  right: -20px;
}

.profile-language-picker {
  position: relative;
  top: unset;
  right: unset;
}

/************************
   DELIVERY ADDRESS
*************************/
.select-address-list {
  padding: 0;
  list-style-type: none;
}

.select-address-list a {
  padding: 15px 0;
}

.select-address-img-box {
  font-size: 30px;
  width: 35px;
  margin-right: 15px;
  text-align: center;
}

.select-address-text-box {
  font-size: 14px;
  line-height: 1.5;
}

.select-address-block {
  min-height: 80dvh;
}

/************************
   CHECKOUT
*************************/
.mt-2rem {
  margin-top: 2rem !important;
}

.checkout-top-icon-box {
  font-size: 25px;
}

.checkout-line {
  font-size: 14px;
  line-height: 1.28;
}

.checkout-item-img-box {
  width: 65px;
  height: 65px;
}

.checkout-item-img-box img {
  width: 100%;
  height: 100%;
}

.checkout-line-box-left,
.checkout-line-box-right {
  height: 1px;
  background-color: var(--color-primary);
  opacity: 0.1;
  flex-grow: 1;
}

.checkout-items-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-top: 30px;
}

.checkout-items-list li {
  margin-bottom: 30px;
}

.checkout-addons {
  line-height: 1.41;
}

.checkout-item-text-box {
  padding-left: 15px;
}

.checkout-tip-box {
  font-size: 14px;
  line-height: 1.5;
}

.tip-buttons {
  padding: 3px;
  border: 1px solid var(--color-primary);
  border-radius: 22px;
  position: relative;
}

.tip-buttons button {
  padding: 10px;
  min-width: 45px;
  border-radius: 25px;
  z-index: 5;
  color: var(--color-primary);
  border: none;
}

.tip-buttons button.active {
  color: var(--color-primary-complementary);
}

.btn-tip-bckg {
  background-color: var(--color-primary) !important;
  border-radius: 22px;
  min-width: 45px;
  height: 36px;
  top: 3px;
  left: 3px;
  z-index: 2;
  transition: 0.3s all;
}

.tip-custom {
  font-size: 14px;
  padding: 10px 20px;
  line-height: 1.12;
}

.tip-form {
  font-size: 14px;
  line-height: 1.25;
  border-radius: 22px;
  border-color: var(--color-primary);
  padding-right: 75px;
  position: relative;
  padding: 12px 70px 12px 22px;
}

.tip-form:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.tip-from-btn {
  position: absolute;
  right: 3px;
  top: 3px;
}

.additional-checkout,
.discount-coupon {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
}

.delivery-details {
  list-style-type: none;
}

.border-top-1 {
  border-top: 1px solid #15224410;
}

.delivery-details-second {
  padding: 0;
  border-bottom: 1px solid #15224410;
}

.delivery-details li {
  margin-bottom: 5px;
}

.details-left {
  font-size: 14px;
  line-height: 1.28;
  opacity: 0.5;
}

.details-right {
  font-size: 14px;
  line-height: 1.28;
  text-align: right;
}

.details-right span {
  margin-bottom: 5px;
}

.pay-info {
  font-size: 14px;
  line-height: 1.28;
}

.pay-info-price {
  font-size: 16px;
}

.checkout-details {
  padding-bottom: 50px;
}

.order-complete-thanks {
  padding: 25px 0 25px 0;
  height: 80vh;
  align-items: center;
  display: flex;
}

.order-complete {
  padding: 25px 0 25px 0;
}

.order-complete-img-box {
  font-size: 50px;
  color: var(--color-success);
  margin-bottom: 15px;
}

.order-complete-info {
  font-size: 25px;
  line-height: 1.33;
  color: var(--color-success);
  margin-bottom: 10px;
}

.order-complete-text-box {
  font-size: 12px;
  line-height: 1.33;
  opacity: 0.5;
}

.ordered-items-list {
  padding: 20px 0 0 0;
  list-style-type: none;
}

.ordered-items-list li {
  margin-bottom: 15px;
}

.ordered-name {
  font-size: 14px;
  line-height: 1.28;
}

.ordered-value {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.28;
}

.ordered-value span {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.cupon-header {
  font-size: 14px;
  line-height: 1.28;
  opacity: 0.5;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
}

.fixed-bottom {
  z-index: 500;
}

.fixed-button-padding {
  padding-bottom: 100px;
}

.expand-btn {
  flex-grow: 2 !important;
  width: 100% !important;
}

.hide-btn {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/************************
    ORDERS
*************************/
.orders-img-box {
  width: 35px;
  height: 35px;
}

.orders-img-box img {
  height: 100%;
  width: 100%;
}

.orders-rest-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.orders-rest-name,
.orders-rest-address {
  width: 100%;
  text-align: start;
  box-sizing: border-box;
}

.orders-rest-address {
  padding-top: 2px;
}

.orders-header {
  padding: 15px 0px 18px 0px;
}

.order-price-box {
  font-size: 10px;
  flex-shrink: 0;
  line-height: 1;
  margin-bottom: 0.5px;
}

.order-price-box span {
  font-size: 16px;
  margin-left: 4px;
}

.orders-rest-address {
  font-size: 12px;
  line-height: 1.3;
  opacity: 0.5;
}

.order-header-line {
  border-bottom: 1px solid var(--color-primary);
  opacity: 0.1;
}

.order-dates {
  padding: 0;
  list-style-type: none;
}

.order-dates li {
  margin-bottom: 10px;
}

.order-dates-text {
  font-size: 14px;
  line-height: 1.2;
}

.order-dates-img-box {
  font-size: 15px;
}

.order-number {
  font-size: 14px;
  line-height: 1.1;
}

.order-items {
  padding: 0;
  font-size: 12px;
  opacity: 0.5;
}

.order-items li {
  margin-bottom: 5px;
}

.hidden-orders li:not(:first-child) {
  display: none !important;
}

.address-buttons {
  padding: 3px;
  border-radius: 22px;
  background-color: var(--color-background);
  border: 1px solid var(--color-primary);
}

.address-btn {
  padding: 13px 21px;
  border: none;
}

.address-btn.active {
  color: #ffffff;
  opacity: 1;
  z-index: 3;
  transition: all 0.3s;
  border-radius: 22px;
  min-width: 88px;
  height: 42px;
  top: 3px;
  left: 3px;
  z-index: 2;
  transition: 0.3s all;
  background-color: var(--color-primary);
}

.address-btn-bckg {
  position: absolute;
  background-color: var(--color-primary);
  border-radius: 22px;
  min-width: 88px;
  height: 42px;
  top: 3px;
  left: 3px;
  z-index: 2;
  transition: 0.3s all;
}

.checbox-asap {
  height: 18px;
  width: 18px;
  transition: all 0.2s;
  margin-right: 10px;
  background-color: var(--color-primary);
}

.checbox-asap:checked {
  accent-color: var(--color-primary);
}

.checbox-asap-label {
  font-size: 12px;
}

.paginator>.mat-paginator>.mat-paginator-outer-container>.mat-paginator-container>.mat-paginator-range-actions>button {
  color: #ffffff;
  background-color: var(--color-primary);
  margin: 3px;
}

.paginator>.mat-paginator>.mat-paginator-outer-container>.mat-paginator-container>.mat-paginator-range-actions>button:disabled {
  opacity: 0.75;
}

.paginator>.mat-paginator>.mat-paginator-outer-container>.mat-paginator-container>.mat-paginator-range-actions>.mat-paginator-range-label {
  margin-left: 0px;
}

/************************
    CART
*************************/

.bag-img-box {
  margin: 62px 0;
  height: 138px;
  width: auto;
}

.bag-img-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.checkout-info-box {
  font-size: 18px;
}

.cart-modal .modal-dialog {
  border-radius: 0;
  margin: 0;
  height: 100%;
}

.cart-modal .modal-content {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 0;
  background-color: var(--color-background);
}

.cart-modal .modal-body {
  height: 100%;
  padding: 0;
  padding-bottom: 160px;
}

.mini-cart-position {
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: 8px;
  padding: 8px;
}

.cart-item-position {
  height: 100%;
  overflow: hidden;
}

@keyframes modal-bottom-to-top {
  0% {
    transform: translateY(70%);
  }

  50% {
    transform: translateY(0);
  }
}

.cart-modal-bottom-to-top .modal-body {
  animation: modal-bottom-to-top 0.75s ease-out;
}

.restaurant-menu-item {
  margin-top: 120px;
}

/************************
    ORDER RATE
*************************/

.rating-icon:hover,
.rating-icon.active {
  border: 1px solid #837f86;
}

.rating-icon {
  width: 56px;
  height: 56px;
  font-size: 28px;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px #00000029;
  border-color: #fff;
}

.rating-icon i {
  background-color: #fce35e;
}

.green-face {
  background-image: linear-gradient(to bottom, #d7f5d3, #96dd99);
}

.raing-text {
  font-size: 12px;
  padding-top: 2px;
}

/************************
    GOOGLE MAP
*************************/

google-map {
  display: flex;
}

.input-with-clear {
  position: relative;
}

.input-clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.5;
}

.input-with-clear:not(:placeholder-shown) .input-clear-icon {
  opacity: 1;
  padding: 5px;
  font-size: 16px;
}

.map-container {
  position: relative;
  width: 100% !important;
}

.map-point {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin-top: -16px;
}

/************************
    RESTAURANTS TABLES
*************************/

.table-card {
  height: 150px;
}

.table-info {
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table-info h5,
.seating-count {
  margin: 0;
}

.seating-count {
  font-size: 14px;
}

.table-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-indicator-close {
  position: absolute;
  top: 2px;
  right: 14px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
}

.dropdown-indicator-open {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
}

.coupon-form {
  font-size: 16px;
  line-height: 1.55;
  border-radius: 22px;
  border-color: var(--color-primary);
  padding-right: 75px;
  position: relative;
  padding: 12px 70px 12px 22px;
}

.coupon-form:focus {
  box-shadow: none;
  border-color: var(--color-primary);
}

.coupon-from-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
}

/************************
    TOASTR
*************************/
.ngb-toasts {
  position: fixed;
  top: 80px;
  width: 100%;
  padding: 0 10px;
  z-index: 10000;

  .toast {
    width: 100%;
  }
}

/************************
    RESPONSIVE SM
*************************/
@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }

  .cart-modal .modal-dialog {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-modal .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }

  .menu-modal .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: auto;
    max-height: 100%;
  }

  .restaurant-menu-item {
    margin-top: 120px;
  }
}

/************************
    RESPONSIVE MD
*************************/
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .cart-modal .modal-dialog {
    max-width: 720px;
  }

  .container-custom {
    max-width: 700px;
  }

  .restaurant-menu-item-header {
    min-height: 160px;
  }

  .restaurant-menu-item {
    margin-top: 120px;
  }
}

/************************
    RESPONSIVE LG
*************************/
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .cart-modal .modal-dialog {
    max-width: 960px;
  }

  .container-custom {
    max-width: 940px;
  }

  .restaurant-menu-item {
    margin-top: 120px;
  }
}

/************************
    RESPONSIVE XL
*************************/
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .cart-modal .modal-dialog {
    max-width: 1140px;
  }

  .container-custom {
    max-width: 1120px;
  }

  .restaurant-menu-item {
    margin-top: 140px;
  }
}

@media (min-height: 600px) {
  .landing-mid-block {
    padding-top: 60px;
  }
}

@media (min-height: 650px) {
  .landing-mid-block {
    padding-top: 100px;
  }
}

@media (min-height: 700px) {
  .landing-mid-block {
    padding-top: 130px;
  }
}

@media (min-height: 800px) {
  .landing-mid-block {
    padding-top: 180px;
  }
}
